import React from "react";
import { navigate } from "gatsby"
import {Container, Row, Col, Button, Input, FormGroup} from "reactstrap";

import "./login.css";

export default function LoginPage() {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [isSigning, setIsSigning] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const onLogin = async () => {
        if(typeof window !== "undefined") {
            const {login} = require("../businessLogic/Firebase");
            login(email, password).then(() => {
                setIsSigning(true);
                navigate("/admin", { replace: true })
            }).catch(() => {
                setIsSigning(false);
                setErrorMessage("Unable to login. Please check your email and password.");
            })
        }
    };

    return (
        <Container className="login-root">
            <Row>
                <Col>
                    <h3>Login</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Input
                            required
                            invalid={email === ""}
                            id="email"
                            name="Email"
                            placeholder="Email Address"
                            type="text"
                            value={email}
                            className={"login-text"}
                            onChange={(e) => {setEmail(e.target.value)}} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Input
                            required
                            invalid={password === ""}
                            id="password"
                            name="Password"
                            placeholder="Password"
                            type="password"
                            value={password}
                            className={"login-text"}
                            onChange={(e) => {setPassword(e.target.value)}} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        color={"primary"}
                        disabled={email === "" || password === ""}
                        onClick={onLogin}
                    >
                        {isSigning ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>
                            : "Login"}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className={"mt-3"}>
                    <p className={"text-danger"}>
                        {errorMessage}
                    </p>
                </Col>
            </Row>
        </Container>
    );
}